import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import termsImg from '../assets//images/terms-of-service.jpg'
import { Helmet } from 'react-helmet'

const MentionsLegales = () => {
    return (
        <Layout>
            <Helmet>
                <title>Seekreet | Mentions Légales</title>
                <meta name="description" content="Seekreet, une équipe spécialisée dans le data management. Mise en conformité compliance, qualité, partage d’informations, nous digitalisons vos process." />
            </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Mentions Légales" 
                homePageText="Accueil" 
                homePageUrl="/" 
                activePageText="Mentions Légales" 
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <img src={termsImg} alt="tt" />
                                <p><i>Ces mentions légales ont été actualisées pour la dernière fois le 26 avril 2022.</i></p>
                                <h3>1. IDENTITÉ</h3>
                                <p>Nom du site web : Seekreet<br></br>
                                Adresse : https://seekreet.com <br></br>
                                Propriétaire : SAS Seekreet<br></br>
                                Responsable de publication : Guillaume HAMON. Le responsable de publication est une personne physique.<br></br>
                                <br></br>
                                Conception et réalisation : Seekreet - 2022<br></br>
                                Animation : SAS Seekreet<br></br>
                                Hébergement : Le site est hébergé chez OVH - 2, rue Kellermann 59100 Roubaix<br></br>
                                </p>
                                <h3>2. CONDITIONS D’UTILISATION DU SITE ET DES SERVICES PROPOSÉS</h3>
                                <p>L’utilisation du site Seekreet implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment.<br></br>
                                Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par Seekreet SAS, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.<br></br>
                                Le site est mis à jour régulièrement par Seekreet SAS. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>
                                <h3>3. DESCRIPTION DES SERVICES FOURNIS</h3>
                                <p>Le site Seekreet décrit l'ensemble des activités de la société.<br></br>
                                Guillaume HAMON fera le nécessaire pour fournir une information aussi précise que possible. Néanmoins, il ne pourra pas être tenu responsable d'informations inexactes, omises ou obsolètes ; que ces informations viennent de lui ou de tiers partenaires lui fournissant les informations.<br></br>
                                Les informations présentées sont à titre indicatives et susceptibles d'évoluer.</p>
                                <h3>4. LIMITATIONS CONTRACTUELLES SUR LES DONNÉES TECHNIQUES</h3>
                                <p>Le site utilise la technologie JavaScript.<br></br>
                                Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis à jour.</p>
                                <h3>5. GESTION DES DONNÉES PERSONNELLES</h3>
                                <p>En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.<br></br>
                                A l’occasion de l’utilisation du site Seekreet peuvent êtres recueillies : l’URL des liens par l’intermédiaire desquels l’utilisateur a accédé au siteSeekreet, le fournisseur d’accès de l’utilisateur, l’adresse de protocole Internet (IP) de l’utilisateur.<br></br>
                                En tout état de cause Seekreet ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés par le site Seekreet. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à l’utilisateur du site Seekreet l’obligation ou non de fournir ces informations.<br></br>
                                Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.<br></br>
                                Aucune information personnelle de l’utilisateur du site Seekreet n’est publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l’hypothèse du rachat de Seekreet et de ses droits permettrait la transmission des dites informations à l’éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l’utilisateur du site Seekreet.<br></br>
                                Le site n’est pas déclaré à la CNIL car il ne recueille pas d’informations personnelles.<br></br>
                                Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>
                                <h3>6. LIENS HYPERTEXTES ET COOKIES</h3>
                                <h4>Les cookies</h4>
                                <p>Le cookie est un fichier texte que nous transférons sur votre ordinateur via votre navigateur Internet. Il a pour but de simplifier votre navigation sur le site Seekreet, notamment en permettant de vous authentifier automatiquement à votre compte lors de votre visite, mais aussi de maintenir votre panier à jour.<br></br>
                                Vous pouvez à tout moment choisir de supprimer ou refuser ces cookies. Pour connaitre la marche à suivre, rendez-vous dans la section « Aide » du site Internet de votre navigateur. Toutefois, nous vous conseillons de les laisser actifs afin de bénéficier de l'ensemble de nos services personnalisés.<br></br>
                                Si vous laissez les « cookies » actifs, assurez-vous de vous déconnecter lorsque vous partagez un ordinateur avec d'autres personnes.</p>
                                <h4>Mesure d'audience</h4>
                                <p>Afin de mieux vous servir, nous mesurons les statistiques relatives à l'activité des visiteurs sur le site Seekreet grâce aux solutions de Google Analytics. Si vous souhaitez désactiver cette mesure d'audience, Google met à disposition un module complémentaire permettant la désactivation de Google Analytics. Vous le trouverez <a href="https://tools.google.com/dlpage/gaoptout?hl=fr" rel="noreferrer" target="_blank">ICI</a>.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                        <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                Accueil
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default MentionsLegales;